import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import QRCode from "./pages/QRCode/QRCode";
import Cricut from "./pages/cricut";
import CalendarIndex from "./pages/calendar";
import Weather from "./pages/weather/Weather";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <div>Hello! Welcome to Peony & Lace Paperie!</div>
        <div>
          Please visit our site at{" "}
          <a href="https://www.etsy.com/shop/PeonyLacePaperie">
            PeonyLacePaperie
          </a>{" "}
          for more details
        </div>
      </div>
    ),
  },
  {
    path: "/qr",
    element: <QRCode />,
  },
  {
    path: "/cricut",
    element: <Cricut />,
  },
  {
    path: "/calendar",
    element: <CalendarIndex />,
  },
  {
    path: "/weather",
    element: <Weather />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
