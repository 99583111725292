import Chart from "chart.js/auto";
import { useEffect, useRef } from "react";
import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";
import "./Weather.css";

Chart.register(annotationPlugin);

function openMeteoAPI() {
  return fetch(
    "https://api.open-meteo.com/v1/forecast?latitude=38.9822&longitude=-94.6708&current=temperature_2m&hourly=temperature_2m,precipitation,wind_speed_10m&daily=uv_index_max&temperature_unit=fahrenheit&wind_speed_unit=mph&precipitation_unit=inch&timezone=auto"
  );
}

function setupChart(elem, weatherData) {
  // Sample data
  const data = {
    datasets: [],
  };

  function toPoint(x, y) {
    return {
      x: x,
      y: y,
    };
  }

  function toDataPoints(times, values) {
    const dataPoints = times.reduce((prev, v, i) => {
      if (values[i]) {
        prev.push(toPoint(v, values[i]));
      }
      return prev;
    }, []);

    return dataPoints;
  }

  // function toDataPoints(times, values) {
  //   const dataPoints = times.map((time, i) => {
  //     return toPoint(time, values[i]);
  //   });

  //   return dataPoints;
  // }

  // Weather
  const weatherHourly = weatherData.hourly;
  const { time, temperature_2m } = weatherHourly;
  const hourlyTempData = toDataPoints(time, temperature_2m);
  // console.log(hourlyChartData)
  addDataset({
    type: "line",
    label: "Temperature",
    data: hourlyTempData,
    borderColor: "black",
    // borderColor: function (context) {
    //   const chart = context.chart;
    //   const { ctx, chartArea } = chart;

    //   if (!chartArea) {
    //     // This case happens on initial chart load
    //     return;
    //   }
    //   return getGradient(ctx, chartArea);
    // },
    backgroundColor: "black",
    tension: 0.2,
  });

  // Wind
  const { wind_speed_10m } = weatherHourly;
  const hourlyWindData = toDataPoints(time, wind_speed_10m);
  addDataset({
    type: "line",
    label: "Wind Speed",
    data: hourlyWindData,
    borderColor: "#5ba748",
    backgroundColor: "#5ba748",
    tension: 0.2,
    yAxisID: "yWind",
    borderWidth: 3,
  });

  // UV
  const weatherDaily = weatherData.daily;
  const { time: dailyTime, uv_index_max } = weatherDaily;
  const dailyUVData = toDataPoints(dailyTime, uv_index_max);
  addDataset({
    type: "line",
    label: "UV",
    data: dailyUVData,
    borderColor: "#ff4b00",
    backgroundColor: "#ff4b00",
    tension: 0.2,
    yAxisID: "yUV",
    borderWidth: 2,
  });

  // precipitation
  const { precipitation } = weatherHourly;
  const hourlyPrecipitationData = toDataPoints(time, precipitation);
  addDataset({
    type: "line",
    label: "Precipitation",
    data: hourlyPrecipitationData,
    borderColor: "#00a1ff80",
    backgroundColor: "#00a1ff60",
    tension: 0.4,
    yAxisID: "yPre",
    stepped: true,
    fill: true,
    borderWidth: 0,
  });

  // Get the canvas element
  const ctx = elem;

  function addDataset(dataset) {
    data.datasets.push(dataset);
  }

  // Chart configuration
  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      // maintainAspectRatio: true,
      animation: false,
      spanGaps: true,
      scales: {
        x: {
          type: "time",
          parsing: false,
          time: {
            unit: "hour", // Adjust as needed (e.g., 'minute', 'day')
            displayFormats: {
              hour: "HH:mm",
              day: "MMM DD",
            },
          },
          ticks: {
            maxRotation: 0,
            callback: function (value, index, ticks) {
              const date = new Date(value);
              if (date.getHours() === 0) {
                return date.getMonth() + "/" + date.getDate();
              }
              // else if (date.getHours() % 3 === 0) {
              //   return date.getHours() % 12;
              // }
              return "";
            },
          },
          grid: {
            lineWidth: 2,
            color: function (context) {
              if (context?.tick?.value > 0) {
                const date = new Date(context?.tick?.value);
                if (date.getHours() === 0) {
                  return "#00000050";
                }
              }

              return "#00000010";
            },
          },
        },
        y: {
          title: {
            display: true,
            text: "Temperature (F)",
          },
          ticks: {
            stepSize: 4,
          },
          offset: 2,
        },
        yWind: {
          type: "linear",
          display: false,
          position: "right",
          min: 0,
          max: 30,
          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        yUV: {
          type: "linear",
          display: false,
          position: "right",
          min: 0,
          max: 12,

          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        yPre: {
          type: "linear",
          display: false,
          position: "right",
          min: 0,
          max: 0.4,
        },
      },
      plugins: {
        legend: {
          position: "top",
        },
        tooltip: {
          mode: "index",
          intersect: false,
        },
        annotation: {
          annotations: {
            verticalLine: {
              type: "line",
              mode: "vertical",
              scaleID: "x", // The scale ID for the x-axis
              value: new Date(), // The x-value where the line should be drawn
              borderColor: "black",
              borderWidth: 1,
              label: {
                content: "Important Event", // Optional label
                enabled: true,
                position: "top",
              },
            },
            horizontalLine: {
              type: "line",
              mode: "vertical",
              scaleID: "y", // The scale ID for the x-axis
              value: 32, // The x-value where the line should be drawn
              borderColor: "#0067ce87",
              borderWidth: 2,
              borderDash: [5, 5],
              label: {
                content: "Important Event", // Optional label
                enabled: true,
                position: "top",
              },
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
    },
  };

  // Render the chart
  new Chart(ctx, config);
}

export default function Weather({}) {
  const chartRef = useRef();
  useEffect(() => {
    (async () => {
      const weatherData = await (await openMeteoAPI()).json();
      // const weatherData = {
      //   latitude: 46.69462,
      //   longitude: -120.75321,
      //   generationtime_ms: 0.09500980377197266,
      //   utc_offset_seconds: -28800,
      //   timezone: "America/Los_Angeles",
      //   timezone_abbreviation: "PST",
      //   elevation: 587,
      //   current_units: {
      //     time: "iso8601",
      //     interval: "seconds",
      //     temperature_2m: "°F",
      //   },
      //   current: {
      //     time: "2024-11-19T08:00",
      //     interval: 900,
      //     temperature_2m: 30.8,
      //   },
      //   hourly_units: {
      //     time: "iso8601",
      //     temperature_2m: "°F",
      //     precipitation: "inch",
      //     wind_speed_10m: "mp/h",
      //   },
      //   hourly: {
      //     time: [
      //       "2024-11-19T00:00",
      //       "2024-11-19T01:00",
      //       "2024-11-19T02:00",
      //       "2024-11-19T03:00",
      //       "2024-11-19T04:00",
      //       "2024-11-19T05:00",
      //       "2024-11-19T06:00",
      //       "2024-11-19T07:00",
      //       "2024-11-19T08:00",
      //       "2024-11-19T09:00",
      //       "2024-11-19T10:00",
      //       "2024-11-19T11:00",
      //       "2024-11-19T12:00",
      //       "2024-11-19T13:00",
      //       "2024-11-19T14:00",
      //       "2024-11-19T15:00",
      //       "2024-11-19T16:00",
      //       "2024-11-19T17:00",
      //       "2024-11-19T18:00",
      //       "2024-11-19T19:00",
      //       "2024-11-19T20:00",
      //       "2024-11-19T21:00",
      //       "2024-11-19T22:00",
      //       "2024-11-19T23:00",
      //       "2024-11-20T00:00",
      //       "2024-11-20T01:00",
      //       "2024-11-20T02:00",
      //       "2024-11-20T03:00",
      //       "2024-11-20T04:00",
      //       "2024-11-20T05:00",
      //       "2024-11-20T06:00",
      //       "2024-11-20T07:00",
      //       "2024-11-20T08:00",
      //       "2024-11-20T09:00",
      //       "2024-11-20T10:00",
      //       "2024-11-20T11:00",
      //       "2024-11-20T12:00",
      //       "2024-11-20T13:00",
      //       "2024-11-20T14:00",
      //       "2024-11-20T15:00",
      //       "2024-11-20T16:00",
      //       "2024-11-20T17:00",
      //       "2024-11-20T18:00",
      //       "2024-11-20T19:00",
      //       "2024-11-20T20:00",
      //       "2024-11-20T21:00",
      //       "2024-11-20T22:00",
      //       "2024-11-20T23:00",
      //       "2024-11-21T00:00",
      //       "2024-11-21T01:00",
      //       "2024-11-21T02:00",
      //       "2024-11-21T03:00",
      //       "2024-11-21T04:00",
      //       "2024-11-21T05:00",
      //       "2024-11-21T06:00",
      //       "2024-11-21T07:00",
      //       "2024-11-21T08:00",
      //       "2024-11-21T09:00",
      //       "2024-11-21T10:00",
      //       "2024-11-21T11:00",
      //       "2024-11-21T12:00",
      //       "2024-11-21T13:00",
      //       "2024-11-21T14:00",
      //       "2024-11-21T15:00",
      //       "2024-11-21T16:00",
      //       "2024-11-21T17:00",
      //       "2024-11-21T18:00",
      //       "2024-11-21T19:00",
      //       "2024-11-21T20:00",
      //       "2024-11-21T21:00",
      //       "2024-11-21T22:00",
      //       "2024-11-21T23:00",
      //       "2024-11-22T00:00",
      //       "2024-11-22T01:00",
      //       "2024-11-22T02:00",
      //       "2024-11-22T03:00",
      //       "2024-11-22T04:00",
      //       "2024-11-22T05:00",
      //       "2024-11-22T06:00",
      //       "2024-11-22T07:00",
      //       "2024-11-22T08:00",
      //       "2024-11-22T09:00",
      //       "2024-11-22T10:00",
      //       "2024-11-22T11:00",
      //       "2024-11-22T12:00",
      //       "2024-11-22T13:00",
      //       "2024-11-22T14:00",
      //       "2024-11-22T15:00",
      //       "2024-11-22T16:00",
      //       "2024-11-22T17:00",
      //       "2024-11-22T18:00",
      //       "2024-11-22T19:00",
      //       "2024-11-22T20:00",
      //       "2024-11-22T21:00",
      //       "2024-11-22T22:00",
      //       "2024-11-22T23:00",
      //       "2024-11-23T00:00",
      //       "2024-11-23T01:00",
      //       "2024-11-23T02:00",
      //       "2024-11-23T03:00",
      //       "2024-11-23T04:00",
      //       "2024-11-23T05:00",
      //       "2024-11-23T06:00",
      //       "2024-11-23T07:00",
      //       "2024-11-23T08:00",
      //       "2024-11-23T09:00",
      //       "2024-11-23T10:00",
      //       "2024-11-23T11:00",
      //       "2024-11-23T12:00",
      //       "2024-11-23T13:00",
      //       "2024-11-23T14:00",
      //       "2024-11-23T15:00",
      //       "2024-11-23T16:00",
      //       "2024-11-23T17:00",
      //       "2024-11-23T18:00",
      //       "2024-11-23T19:00",
      //       "2024-11-23T20:00",
      //       "2024-11-23T21:00",
      //       "2024-11-23T22:00",
      //       "2024-11-23T23:00",
      //       "2024-11-24T00:00",
      //       "2024-11-24T01:00",
      //       "2024-11-24T02:00",
      //       "2024-11-24T03:00",
      //       "2024-11-24T04:00",
      //       "2024-11-24T05:00",
      //       "2024-11-24T06:00",
      //       "2024-11-24T07:00",
      //       "2024-11-24T08:00",
      //       "2024-11-24T09:00",
      //       "2024-11-24T10:00",
      //       "2024-11-24T11:00",
      //       "2024-11-24T12:00",
      //       "2024-11-24T13:00",
      //       "2024-11-24T14:00",
      //       "2024-11-24T15:00",
      //       "2024-11-24T16:00",
      //       "2024-11-24T17:00",
      //       "2024-11-24T18:00",
      //       "2024-11-24T19:00",
      //       "2024-11-24T20:00",
      //       "2024-11-24T21:00",
      //       "2024-11-24T22:00",
      //       "2024-11-24T23:00",
      //       "2024-11-25T00:00",
      //       "2024-11-25T01:00",
      //       "2024-11-25T02:00",
      //       "2024-11-25T03:00",
      //       "2024-11-25T04:00",
      //       "2024-11-25T05:00",
      //       "2024-11-25T06:00",
      //       "2024-11-25T07:00",
      //       "2024-11-25T08:00",
      //       "2024-11-25T09:00",
      //       "2024-11-25T10:00",
      //       "2024-11-25T11:00",
      //       "2024-11-25T12:00",
      //       "2024-11-25T13:00",
      //       "2024-11-25T14:00",
      //       "2024-11-25T15:00",
      //       "2024-11-25T16:00",
      //       "2024-11-25T17:00",
      //       "2024-11-25T18:00",
      //       "2024-11-25T19:00",
      //       "2024-11-25T20:00",
      //       "2024-11-25T21:00",
      //       "2024-11-25T22:00",
      //       "2024-11-25T23:00",
      //     ],
      //     temperature_2m: [
      //       32.3, 31.4, 30, 26.9, 29.5, 30.8, 31.2, 30.9, 30.8, 31, 32.4, 33.9,
      //       34.8, 36.5, 37.8, 38.8, 38.7, 36.8, 36.3, 35.9, 34.2, 32.6, 32.4,
      //       32.3, 32.3, 32.5, 32.6, 32.3, 32.5, 32.8, 33.6, 32.1, 33.4, 35.1,
      //       36.4, 38.3, 39, 39.6, 39, 37.4, 36.6, 35.5, 35.1, 35.3, 35.6, 35.8,
      //       34.8, 34.2, 33.8, 33.3, 33.8, 34.3, 34.9, 33.2, 32.7, 32.6, 32.6,
      //       32.8, 32.9, 33.2, 33.4, 33.5, 33, 32.9, 33.2, 33.2, 33.2, 33.3,
      //       33.5, 33.6, 33.7, 33.5, 33.6, 33.7, 33.6, 33.8, 34.1, 34.1, 34.7,
      //       35, 35.2, 34.5, 34.3, 34.5, 34.6, 36.8, 35, 34.7, 34.5, 34.3, 34.3,
      //       33.3, 32.9, 29.7, 33.9, 31.4, 31.9, 29, 28.8, 26.9, 26.9, 27.8,
      //       28.3, 28.7, 29.1, 32.7, 34.2, 35, 34.3, 34.1, 33.6, 33.6, 33.7,
      //       32.8, 31.6, 30.5, 29.6, 28.8, 28.3, 28.1, 28.1, 27.8, 27.1, 26.2,
      //       25.4, 24.6, 24, 24.3, 26.4, 29.4, 31.7, 32.8, 33.2, 33, 32.2, 30.9,
      //       29.6, 28.6, 27.7, 26.9, 26.7, 26.8, 26.7, 26.4, 26, 25.7, 25.2,
      //       24.8, 25, 26.1, 27.8, 29.4, 30.6, 31.6, 32.4, 33, 33.2, 32.9, 31.7,
      //       30.1, 29, 29.2, 30, 30.5, 30, 29.3, 28.7, 28.7,
      //     ],
      //     precipitation: [
      //       0, 0, 0, 0, 0.008, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.012,
      //       0.063, 0.157, 0.146, 0.169, 0.126, 0.114, 0.087, 0.087, 0.055,
      //       0.016, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      //       0, 0, 0, 0.004, 0.039, 0.059, 0.043, 0.055, 0.035, 0.024, 0.02,
      //       0.043, 0.035, 0.035, 0.02, 0.016, 0.016, 0.008, 0.02, 0.012, 0.024,
      //       0.02, 0.004, 0.004, 0, 0.008, 0.028, 0.031, 0.043, 0.035, 0.051,
      //       0.008, 0, 0, 0.008, 0.083, 0.008, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      //       0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      //       0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      //       0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      //       0,
      //     ],
      //     wind_speed_10m: [
      //       0.9, 1, 0.9, 3.3, 5, 2.8, 1.4, 2.6, 2.7, 2.5, 3, 3.6, 2.7, 3.8, 5.3,
      //       5.7, 9.1, 10, 5.2, 5, 7.4, 7.4, 8.3, 6.1, 5.3, 6.1, 4.4, 5.5, 5.4,
      //       6, 4.1, 5.9, 4.3, 2.9, 1.4, 1.1, 2.5, 3.7, 3.5, 5.9, 2.4, 5.4, 3.8,
      //       3.2, 1.3, 3.9, 3.5, 4.5, 4.4, 2.6, 5.2, 2.1, 4.6, 2.5, 1.8, 3.6,
      //       3.7, 2.1, 3.2, 3.6, 2.9, 2, 1.9, 3.4, 2.9, 1.4, 2.9, 2.3, 3.2, 2.8,
      //       2.5, 2.7, 3.5, 2.5, 3, 3.5, 3.5, 3.5, 4.3, 2.3, 3.5, 3.4, 3.9, 3.5,
      //       3.3, 1.4, 2.6, 3.8, 3.1, 3.1, 3.3, 4.3, 6.4, 5.4, 4.5, 2.5, 5.4,
      //       5.3, 6.6, 6.6, 6.5, 6.8, 5.9, 6.7, 6.7, 5.2, 3.2, 2.7, 2.5, 3.6,
      //       3.2, 3.8, 3.3, 3.8, 4.9, 5.8, 6.4, 7, 7.2, 6.9, 6.6, 6.4, 6.5, 6.8,
      //       7, 7.3, 7.3, 7, 5.9, 4.1, 2.8, 1.9, 1.1, 0.7, 1.3, 2.2, 3.4, 4.5,
      //       5.3, 5.9, 5.7, 5.4, 5.2, 5.4, 5.6, 5.9, 6.2, 6.5, 6.5, 6.2, 5.7,
      //       4.9, 4, 2.6, 1.6, 0.7, 0.5, 0.9, 0.4, 1.1, 2.1, 2.2, 2.1, 2.1, 2.5,
      //       3.2, 3.6, 3.7,
      //     ],
      //   },
      //   daily_units: {
      //     time: "iso8601",
      //     uv_index_max: "",
      //   },
      //   daily: {
      //     time: [
      //       "2024-11-19",
      //       "2024-11-20",
      //       "2024-11-21",
      //       "2024-11-22",
      //       "2024-11-23",
      //       "2024-11-24",
      //       "2024-11-25",
      //     ],
      //     uv_index_max: [1.25, 1.4, 0.35, 0.6, 2.2, 2.15, 1.9],
      //   },
      // };
      setupChart(chartRef.current, weatherData);
    })();
  }, []);

  return (
    <div className="chartContainer">
      Weather
      <canvas
        ref={chartRef}
        id="multiLineChart"
        width="800"
        height="400"
      ></canvas>
    </div>
  );
}

let width, height, gradient;
function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, 400, 0, 0);
    gradient.addColorStop(0, "blue");
    gradient.addColorStop(0.5, "yellow");
    gradient.addColorStop(1, "red");
  }

  return gradient;
}
